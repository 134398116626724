<template>
    <div class="footer">
        <ul>
            <li class="mb-3">Quick Links</li>
            <li>
                <router-link
                    to="/"
                    class="btn active-btn text-white px-4 shadow-none"
                    >Home</router-link
                >
            </li>
            <li>
                <router-link to="/about" class="btn shadow-none"
                    >About</router-link
                >
            </li>
            <li>
                <router-link to="/blog" class="btn shadow-none"
                    >Blog</router-link
                >
            </li>
            <li>
                <router-link to="/contact" class="btn shadow-none"
                    >Contact</router-link
                >
            </li>
        </ul>

        <ul>
            <li class="mb-3">Contact Us</li>
            <li><i class="fa-solid fa-envelope" style="color: #e77600"></i> cdivvine@gmail.com</li>
            <li>
                <a href="https://www.facebook.com/cdivvine/"
                    ><i class="fa-brands fa-facebook-square" style="color: #e77600"></i> C Divine</a
                >
            </li>
            <li>
                <a href="https://www.instagram.com/cdivvine/"
                    ><i class="fa-brands fa-instagram-square" style="color: #e77600"></i> @cdivvine</a
                >
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Footer",
};
</script>

<style>
.active-btn {
    background-color: #e77600;
}

.active-btn:hover {
    background-color: #007bff;
}
.footer{
    background: url('/images/footer.jpg');
}
.footer {
    display: flex;
    justify-content: center;
    gap: 35px;
    padding: 45px;
    margin: 0;
    background-color: #323232;
    color: white;
}

.footer ul {
    list-style: none;
}

.footer .contact {
    background-color: #f4f7fc;
    /* background-image: linear-gradient(60deg, #686dff, #f4f7fc); */
}

.footer ul a {
    text-decoration: none;
    color: white;
}


.footer ul a:hover {
    color: white;
}
</style>
