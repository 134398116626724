import { createRouter, createWebHistory } from "vue-router";
import HomePage from '../../components/HomePage.vue'
import About from '../../components/About/About.vue';
import Blog from "../../components/Blog/Blog.vue";
import Contact from "../../components/Contact/Contact.vue";
import Dashboard from "../../components/Dashboard/Dashboard.vue";

const routes = [
    {
        path: '/',
        component:HomePage
    },
    {
        path: '/about',
        component: About
    }
    ,
    {
        path: '/blog',
        component: Blog
    }
    ,
    {
        path: '/contact',
        component: Contact
    }
    ,
    {
        path: '/home',
        component: Dashboard
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;
