<template>
    <div>
        <div
            class="container-jumbotron p-3 rounded"
            style="
                background-color: #f8fcff;
                background-image: url('/images/bookstore.jpg');
            "
        >
            <div class="container-jumbotron">
                <swiper
                    :modules="modules"
                    :slides-per-view="1"
                    :space-between="50"
                    navigation
                    :autoplay="{
                        delay: 3500,
                        disableOnInteraction: true,
                        pauseOnMouseEnter: true,
                    }"
                >
                    <swiper-slide>
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div
                                class="col-md-5 p-4 d-flex justify-content-center align-items-center"
                                data-aos="fade-down"
                                data-aos-duration="500"
                            >
                                <img
                                    src="/images/paperback.png"
                                    alt=""
                                    class="pb-4"
                                    style="width: 60%"
                                />
                            </div>
                            <div
                                class="col-md-5 p-4 d-flex flex-column justify-content-center about-book"
                                data-aos="fade-down"
                                data-aos-anchor-placement="top-bottom"
                            >
                                <h1
                                    class="description animate__animated animate__bounce animate__delay-1s animate__slow"
                                    data-aos="fade-down"
                                    data-aos-duration="1000"
                                    style="font-size: 40px"
                                >
                                    Then They Shall Fast
                                </h1>
                                <p
                                    class="pt-2"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >
                                    Fasting doesn’t change God, it changes us.
                                </p>
                                <p
                                    class="pt-2"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >
                                    'Then they shall fast', tells us of a time
                                    that fasting will be possible, powerful and
                                    profitable because Jesus will be physically
                                    away but spiritually present in man as The
                                    Holy Spirit...and God really wants that!
                                    When you fast and pray you streamline your
                                    flesh to materialize the divine in the
                                    natural...
                                    <a
                                        href="#then-they-shall"
                                        style="
                                            width: 40px;
                                            border: none;
                                            color: #e77600;
                                        "
                                        ><i class="fa fa-angles-down"></i
                                    ></a>
                                </p>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div
                                class="col-md-5 p-4 d-flex justify-content-center align-items-center"
                                data-aos="fade-down"
                                data-aos-duration="500"
                            >
                                <img
                                    src="/images/hardcopy.png"
                                    alt=""
                                    style="width: 65%"
                                    class="pb-4"
                                />
                            </div>
                            <div
                                class="col-md-5 p-4 d-flex flex-column justify-content-center about-book"
                                data-aos="fade-down"
                                data-aos-anchor-placement="top-bottom"
                            >
                                <h1
                                    class="description animate__animated animate__bounce animate__delay-1s animate__slow"
                                    data-aos="fade-down"
                                    data-aos-duration="1000"
                                    style="font-size: 80px"
                                >
                                    E-Book
                                </h1>
                                <p>Discover "The 3P Concept."</p>
                                <p
                                    class="pt-2"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >
                                    What Makes the Discipline of Fasting
                                    Possible, Powerful and Profitable...
                                    <a
                                        href="#then-they-shall"
                                        style="
                                            width: 40px;
                                            border: none;
                                            color: #e77600;
                                        "
                                        ><i class="fa fa-angles-down"></i
                                    ></a>
                                </p>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div
                                class="col-md-5 p-4 d-flex justify-content-center align-items-center"
                                data-aos="fade-down"
                                data-aos-duration="500"
                            >
                                <img
                                    src="/images/hardcover-mockup.png"
                                    alt=""
                                    style="width: 47%"
                                    class="pb-4"
                                />
                            </div>
                            <div
                                class="col-md-5 p-4 d-flex flex-column justify-content-center about-book"
                                data-aos="fade-down"
                                data-aos-anchor-placement="top-bottom"
                            >
                                <h1
                                    class="description animate__animated animate__bounce animate__delay-1s animate__slow"
                                    data-aos="fade-down"
                                    data-aos-duration="1000"
                                    style="font-size: 80px"
                                >
                                    Hard Cover
                                </h1>
                                <p
                                    class="pt-2"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >
                                    True fasting does not take the place of
                                    grace - It is, because of grace. Do not fast
                                    to be merited, fast because you have
                                    received unmerited favor. Fast and pray
                                    because you must manifest your Sonship in
                                    God to a creation that is is groaning in
                                    expectation.
                                    <a
                                        href="#then-they-shall"
                                        style="
                                            width: 40px;
                                            border: none;
                                            color: #e77600;
                                        "
                                        ><i class="fa fa-angles-down"></i
                                    ></a>
                                </p>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <div class="book-display" id="then-they-shall">
            <ul>
                <li>
                    <img src="/images/paperback.jpeg" alt="" />
                    <div class="details">
                        <p>Paper Back</p>
                        <p>$19.99</p>
                        <a
                            href="https://www.amazon.com/gp/aw/d/9914406378/ref=tmm_pap_swatch_0?ie=UTF8&qid=&sr=#"
                            target="_blank"
                        >
                            Buy Now
                        </a>
                    </div>
                </li>
                <li>
                    <img src="/images/hardcover.jpeg" alt="" />
                    <div class="details">
                        <p>Hard Cover</p>
                        <p>$29.99</p>
                        <a
                            href="https://www.amazon.com/gp/aw/d/991440636X/ref=tmm_hrd_swatch_0?ie=UTF8&qid=&sr="
                            target="_blank"
                            >Buy Now
                        </a>
                    </div>
                </li>
                <li>
                    <img src="/images/hardcopy.png" alt="" />
                    <div class="details">
                        <p>Kindle</p>
                        <p>$9.99</p>
                        <a
                            href="https://www.amazon.com/THEN-THEY-SHALL-FAST-Discipline-ebook/dp/B0BBT4F1W7/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=&sr="
                            target="_blank"
                            >Buy Now
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <div
            class="container mt-md-5 d-flex flex-column justify-content-center align-items-center"
        >
            <div class="col-md-7">
                <ul class="errors">
                        <li
                            v-for="(error, index) in errors2"
                            :key="index"
                            class="text-danger"
                        >
                            {{ error[0] }}
                        </li>
                    </ul>
                <h1>
                    Order a hard copy in
                    <span class="apple fw-bolder">Kenya</span>
                </h1>
                <div class="form-group mb-3">
                    <input
                        v-model="form2.name"
                        type="text"
                        placeholder="Name"
                        class="form-control bg-white shadow-0"
                    />
                </div>
                <div class="form-group mb-md-2">
                    <input
                        v-model="form2.mobile"
                        type="text"
                        placeholder="Mobile Number"
                        class="form-control bg-white shadow-0"
                    />
                </div>
                <div class="d-flex justify-content-end mt-2">
                    <button
                        class="btn btn-primary"
                        @click="handleKenyaOrder"
                        :disabled="disableKenyaOrder"
                    >
                        Request order
                    </button>
                </div>
            </div>
        </div>
        <div
            class="container mt-md-3 d-flex justify-content-center align-items-center"
        >
            <div
                class="col-md-7 apple-link d-flex align-items-center gap-md-5 gap-2"
            >
                <img src="/images/apple.png" alt="" />
                <div>
                    <h1>
                        Get on <span class="apple fw-bolder">Apple</span> Books
                    </h1>
                    <a
                        target="_blank"
                        alt="apple books"
                        href="https://books.apple.com/us/book/id6443331220"
                        >Get Now
                        <span><i class="fa-solid fa-arrow-right"></i></span
                    ></a>
                </div>
            </div>
        </div>

        <div style="margin-top: 60px">
            <div
                class="comment-section container mt-md-5 d-flex flex-column justify-content-center align-items-center"
                style="margin-top: 60px"
            >
                <div class="col-md-7">
                    <h4>Leave a review</h4>
                    <p>Your review will help us inspire more people.</p>
                    <p>{{ thankYouMessage }}</p>
                    <ul class="errors">
                        <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="text-danger"
                        >
                            {{ error[0] }}
                        </li>
                    </ul>
                    <div class="mb-3">
                        <input
                            type="text"
                            placeholder="Your name"
                            class="form-control bg-white shadow-none"
                            v-model="form.name"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <textarea
                            class="form-control shadow-none bg-white"
                            placeholder="Write a review here..."
                            cols="10"
                            required
                            v-model="form.review"
                        ></textarea>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button
                            class="btn btn-primary mt-2 shadow-none"
                            @click="submitReview"
                            :disabled="disableSubmitButton"
                        >
                            Submit Review
                        </button>
                    </div>
                    <h4>Reviews</h4>
                    <div class="mt-3 p-2 comments w-100">
                        <div
                            class="w-100 mb-3 review-container"
                            v-for="reviewer in allReviews"
                            :key="reviewer"
                        >
                            <img
                                src="/images/avatar.png"
                                alt=""
                                class="rounded-circle me-2 border bg-white p-2 shadow"
                                style="width: 60px; height: 60px"
                            />
                            <span>{{ reviewer.name }} </span>
                            <p>{{ reviewDate(reviewer.created_at) }}</p>
                            <p>{{ reviewer.review }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Autoplay, Scrollbar } from "swiper";
import "swiper/css/bundle";
import axios from "axios";
export default {
    name: "BookDisplay",
    data() {
        return {
            comments: [],
            modules: [Navigation, Autoplay, Scrollbar],
            disableSubmitButton: false,
            disableKenyaOrder: false,
            form: {
                name: null,
                review: null,
            },
            form2: {
                name: null,
                mobile: null,
            },
            errors: null,
            errors2: null,
            thankYouMessage: null,
            orderMessage: null,
            allReviews: null,
        };
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    mounted() {
        this.getReviews();
    },
    methods: {
        handleKenyaOrder() {
            this.disableKenyaOrder = true;
            this.orderMessage = "Sending your request...";
            axios
                .post("/abroad-orders", this.form2)
                .then((res) => {
                    this.errors2 = "";
                    this.orderMessage =
                        "Thank you for your order request " +
                        this.form2.name +
                        "!";
                    this.form2.name = "";
                    this.form2.mobile = "";
                })
                .catch((err) => {
                    if (err.response.status === 422) {
                        this.errors2 = error.response.data.errors;
                    } else {
                        alert("Error: " + err.message);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.disableKenyaOrder = false;
                    }, 1500);
                });
        },
        reviewDate(dt) {
            return moment(dt).calendar();
        },
        getReviews() {
            axios
                .get("/get-all-reviews")
                .then((response) => {
                    this.allReviews = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        submitReview() {
            this.disableSubmitButton = true;
            this.thankYouMessage = "Sending your review...";
            axios
                .post("/send-review", this.form)
                .then(() => {
                    this.getReviews();
                    this.errors = "";
                    this.thankYouMessage =
                        "Thank you for your review " + this.form.name + "!";
                    this.form.name = "";
                    this.form.review = "";
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.disableSubmitButton = false;
                    }, 1500);
                });
        },
    },
};
</script>

<style>
.apple-link {
    /* display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px; */
    padding: 10px;
    margin-top: 30px;
    flex-flow: wrap;
}
.apple-link img {
    border-radius: 50%;
    width: 100px;
}
@media only screen and (max-width: 667px) {
    .apple-link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 30px;
        flex-flow: wrap;
    }
    .apple-link div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .book-display ul {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-flow: wrap;
        list-style: none;
        gap: 50px;
        padding: 0;
    }

    /* .book-display ul li:nth-child(3) img {
        width: 220px;
        border-radius: 10px 10px 0px 0px;
        position: relative !important ;
    } */
}

.book-display {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.book-display ul {
    display: flex;
    justify-content: center;
    flex-direction: row;
    list-style: none;
    gap: 50px;
    /* flex-flow: wrap column; */
    padding: 0;
}

.book-display ul li {
    width: 220px;
    height: 344px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
}

.book-display ul li:hover {
    cursor: pointer;
    border: 1px solid -webkit-linear-gradient(to right, #f5af19, #f12711);
    border: 1px solid linear-gradient(to right, #f5af19, #f12711);
}

.book-display .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    margin-top: 10px;
    height: 140px;
    background: white;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.book-display .details p {
    margin: 2px;
    /* color: white; */
}

.book-display .details p:nth-child(1) {
    font-weight: 800;
    font-size: 18px;
}

.book-display .details a {
    text-decoration: none;
    padding: 0px 40px;
    background: #f12711;
    background: #8a2387;
    background: -webkit-linear-gradient(to right, #f27121, #e94057, #8a2387);
    background: linear-gradient(to right, #f27121, #e94057, #8a2387);
    color: white;
    font-weight: 600;
    border-radius: 50px;
    font-size: 24px;
    border: 1px solid white;
}

.book-display ul li img {
    width: 220px;
    /* height: 450px; */
    border-radius: 10px 10px 0px 0px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* opacity: 0.8; */
}

/* .book-display ul li:nth-child(3) img {
    width: 220px;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    top: 0;
} */

.review-container {
    position: relative;
    background: rgb(221, 223, 255);
    background: -moz-linear-gradient(
        122deg,
        rgba(221, 223, 255, 1) 25%,
        rgba(255, 210, 163, 1) 100%
    );
    background: -webkit-linear-gradient(
        122deg,
        rgba(221, 223, 255, 1) 25%,
        rgba(255, 210, 163, 1) 100%
    );
    background: linear-gradient(
        122deg,
        rgba(221, 223, 255, 1) 25%,
        rgba(255, 210, 163, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dddfff", endColorstr="#ffd2a3", GradientType=1);
    padding: 10px 60px;
    border-radius: 20px;
}

.review-container img {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
}

.prices {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.prices a {
    text-decoration: none;
    color: #000000;
}

.prices li {
    border: 1px solid #007bff;
    width: 150px;
    padding: 5px 20px;
    background-color: #fef8f2;
    border-radius: 10px;
}

.amazon-btn {
    border: 1px solid #e77600;
    background-color: #e77600;
    color: white;
}

.prices li p {
    margin-top: 0;
    margin-bottom: 0;
}

.prices li p:first-child {
    color: #e77600;
}

.book-detail p:first-child {
    color: #e77600;
}

.book-detail a {
    text-decoration: none;
    color: #000000;
}

.amazon-btn {
    border: 1px solid #e77600;
    background-color: #e77600;
    color: white;
}

.book-detail p {
    margin-top: 0;
    margin-bottom: 0;
}

body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: #e77600;
    /* outline: 1px solid slategrey; */
}

body::-webkit-scrollbar-thumb:hover {
    background-color: #007bff;
    cursor: pointer;
}

.comments::-webkit-scrollbar {
    width: 5px;
}

/* .comments::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

.comments::-webkit-scrollbar-thumb {
    background-color: #efefef;
}

.comments {
    /* height: 550px; */
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.copies ul {
    list-style: none;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-button-next {
    background-color: #007bff !important;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-next:hover {
    background-color: #e77600 !important;
}

.swiper-button-next::after {
    color: white;
    font-size: 14px;
}

.swiper-button-prev:hover {
    background-color: #e77600 !important;
}

.swiper-button-prev::after {
    color: white;
    font-size: 14px;
}

.swiper-button-prev {
    background-color: #007bff !important;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-prev::after {
    color: white;
    font-size: 14px;
}

textarea {
    resize: none;
}

.buy-amazon {
    border: 0;
    border-radius: 5px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    transition: ease-in 0.35s;
    /* box-shadow: 0px 22px 28px 7px rgba(0, 0, 0, 0.08); */
}

.icons {
    display: flex;
    gap: 15px;
}

.icons button {
    border: 0px;
    border-radius: 100%;
    background-color: #ff4e00;
    color: #fff;
    width: 40px;
    height: 40px;
    transition: ease-in 0.35s;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important; */
}

.icons button:hover {
    background-color: #ff4e00;
}

.description {
    font-family: "Montserrat", sans-serif;
    /* color: #0083ff; */
    font-weight: 600;
}

.bookSection {
    background-color: #f8fcff;
    padding: 10px;
}

.book-details p {
    margin-bottom: 3px;
}

.book-main-title {
    font-family: "Montserrat";
    font-weight: 500;
    /* color: #0083ff; */
}

.book-sub-title,
.book-price {
    font-family: "Montserrat";
    font-weight: 500;
    /* font-size: 20px; */
    margin-top: 0px;
}

.book-sub-title,
.book-price {
    font-family: "Montserrat";
    font-weight: 500;
    /* font-size: 20px; */
}

.discount-price {
    color: #ff4e00;
}

.save-price {
    text-decoration: line-through;
}
</style>
