<template>
    <div class="p-5">
        <div class="row ">
            <div class="col-md-3"></div>
            <div class="col-md-6 p-5 mb-3 bg-white rounded ">
                <p class="text-center fw-bold stay-touch" style="font-size: 53px; color: #e77600;" data-aos="fade-up">STAY WITH US</p>
                <p class="text-center" data-aos="slide-down">Subscribe to our newsletters now and stay up-to-date with
                    new collections, the
                    latest lookbooks and exclusive offers.</p>
                <div class="mb-3 position-relative newsLetterContainer" data-aos="slide-up">
                    <input type="text" class="form-control shadow-none bg-white search-input"
                        placeholder="Enter your email" v-model="email">
                    <button class="btn text-white search-btn position-absolute shadow-none"
                        @click="EmailSubscription">Subscribe</button>
                        <ul>
                            <li v-for="error in errors" :key="error" class="text-danger">
                                {{ error[0] }}
                            </li>
                        </ul>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'NewsLetter',
    data() {
        return {
            email: null,
            errors: null
        }
    },
    methods: {
        EmailSubscription() {
            const data = { email: this.email }
            this.errors = null
            axios.post('/send-newsletters', data)
            .then((response) => {
                this.email = ''
                const successMessage = 'We have received the newsletter subscription'
                document.querySelector('.newsLetterContainer').innerHTML = successMessage
            })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
            ;
        }
    }
}
</script>

<style lang="scss" scoped>
ul{
    list-style: none;
}
    
.search-btn {
    top: 0px;
    width: 150px;
    height: 60px;
    border-radius: 50px;
    right: 0;
    background-color: #007BFF;
}
.search-btn:hover{
    background-color: #007BFF;
}
.search-input:focus {
    border: 1px solid #007BFF;
}

.search-input {
    border: 1px solid #e77600;
    height: 60px;
    width: 100%;
}
</style>
