<template>
    <div class="pb-5">
        <h1 style="font-size: 60px" class="text-center pt-5">Coming Soon!</h1>
        <p class="text-center ">Something powerful, motivational and spiritual is going to be here soon.</p>
    </div>
</template>

<script>
    export default {
        name: 'BlogContent'
    }
</script>

<style lang="scss" scoped>

</style>