<template>
    <div>
        <NavBar :BlogPage="BlogPage"/>
        <BlogContent/>
        <Footer/>
    </div>
</template>

<script>
import NavBar from '../NavBar.vue';
import Footer from '../Home/Footer.vue';
import BlogContent from './BlogContent.vue';
    export default {
    components: { NavBar, Footer, BlogContent },
    name: "Blog",
    data() {
        return {
            BlogPage: false,
        }
    },
    mounted () {
        this.BlogPage = true;
    },
}
</script>

<style lang="scss" scoped>

</style>
