<template>
    <div class="container mt-3">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 bg-white shadow-sm mb-3 p-5">
                <form @submit.prevent="sendMessage">
                    <h3 class="text-center">SEND ME MESSAGE</h3>
                    <p>{{thankYouMessage}}</p>
                    <ul class="errors">
                        <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="text-danger"
                        >
                            {{ error[0] }}
                        </li>
                    </ul>
                    <div class="mb-3">
                        <input
                            type="text"
                            class="form-control shadow-none"
                            placeholder="Enter your name"
                            v-model="form.name"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <input
                            type="email"
                            class="form-control shadow-none"
                            placeholder="Enter your email"
                            v-model="form.email"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <textarea
                            cols="10"
                            rows="5"
                            class="form-control shadow-none"
                            placeholder="Write message here..."
                            v-model="form.message"
                            required
                        ></textarea>
                    </div>
                    <div class="d-flex justify-content-end mb-3">
                        <button
                            class="btn btn-primary mb-3 shadow-none"
                            @click="sendMessage"
                            :disabled="disableSendButton"
                        >
                            Send Message
                        </button>
                    </div>
                </form>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "ContactContent",
    data() {
        return {
            form: {
                name: null,
                email: null,
                message: null,
            },
            errors: "",
            disableSendButton: false,
            thankYouMessage: null,
        };
    },
    methods: {
        sendMessage() {
            this.disableSendButton = true;
            axios
                .post("/send-message", this.form)
                .then(() => {
                    this.errors = "";
                    this.thankYouMessage = 'Thank you for your message ' + this.form.name +'!'
                    this.form.name = ''
                    this.form.email = ''
                    this.form.message = ''

                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    setTimeout(() =>{
                        this.disableSendButton = false;
                    }, 1500);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.errors {
    // padding: 0;
    margin: 0;
}
</style>
