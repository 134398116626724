<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-light bg-white p-5">
            <div class="container">
                <a class="navbar-brand" href="/">
                    <div class="nav-column">
                    <img
                        src="/images/divinelogo.png"
                        alt="divinelogo"
                        width="80"
                    />
                    <p >Inspiring people</p>
                    </div>

                </a>
                <button
                    class="navbar-toggler shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <!-- Left Side Of Navbar -->
                    <ul class="navbar-nav me-auto"></ul>

                    <!-- Right Side Of Navbar -->
                    <ul class="navbar-nav main-nav">
                        <li
                            class="px-4 py-0 rounded-3"
                            :class="HomePage ? 'HomePage' : ''"
                        >
                            <router-link class="nav-link" to="/">
                                Home
                            </router-link>
                        </li>
                        <li
                            class="px-4 py-0 rounded-3"
                            :class="AboutPage ? 'AboutPage' : ''"
                        >
                            <router-link class="nav-link" to="/about">
                                About
                            </router-link>
                        </li>
                        <li
                            class="px-4 py-0 rounded-3"
                            :class="BlogPage ? 'BlogPage' : ''"
                        >
                            <router-link class="nav-link" to="/blog">
                                Blog
                            </router-link>
                        </li>
                        <li
                            class="px-4 py-0 rounded-3"
                            :class="ContactPage ? 'ContactPage' : ''"
                        >
                            <router-link class="nav-link" to="/contact">
                                Contact
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;

export default {
    name: "Navbar",
    props: ["AboutPage", "HomePage", "BlogPage", "ContactPage"],
    data() {
        return {};
    },
    methods: {},
    mounted() {
        console.log(this.AboutPage);
        $(".small-nav ul").hide();
        $(".toggleBtn").click(() => {
            $(".small-nav ul").slideToggle();
        });
    },
};
</script>

<style>
    .nav-link {
        color: #007bff !important;
    }
.AboutPage {
    background-color: #e77600;
    padding: 2px 15px;
}

.AboutPage a {
    color: white !important;
}

.HomePage {
    background-color: #e77600;
    padding: 2px 15px;
}
.HomePage:hover {
    background-color: #007bff;
}
.ContactPage:hover {
    background-color: #007bff;
}
.BlogPage:hover {
    background-color: #007bff;
}
.AboutPage:hover {
    background-color: #007bff;
}

.HomePage a {
    color: white !important;
}

.BlogPage {
    background-color: #e77600;
    padding: 2px 15px;
}

.BlogPage a {
    color: white !important;
}

.ContactPage {
    background-color: #e77600;
    padding: 2px 15px;
}

.ContactPage a {
    color: white !important;
}

.search-icon {
    right: 15px;
    top: 8px;
    border: 0;
}

.search-input {
    border-radius: 35px;
    background-color: #fff;
}

.active-btn a {
    color: white !important;
}

.toggleBtn {
    background-color: #fff;
    font-size: 30px;
}

.toggleBtn:focus {
    outline: none;
    /* box-shadow: none; */
}

.brand-link {
    text-decoration: none;
    padding: 0;
    margin-top: 0;
    /* font-family: 'Playfair Display', serif; */
}

.brand-link img {
    width: 150px;
}

nav {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    padding: 15px 70px;
}

.small-nav ul {
    list-style: none;
    padding: 0px 38px;
    margin: 0;
}

.small-nav ul a {
    text-decoration: none;
    font-size: large;
    color: #323232;
}

.main-nav {
    display: flex;
    justify-content: flex-end;
    /* gap: 50px; */
    list-style: none;
    padding: 0;
    margin: 0;
}

.main-nav a {
    text-decoration: none;
    font-size: large;
    color: #007bff;
    font-weight: 500;
}

.nav-column{
    display: flex;
    flex-direction: column;
}
.nav-column p{
    font-size: 12px;
    font-weight:500;
    margin-left: 4px;
}

@media only screen and (max-width: 766px) {
    .small-nav {
        display: block;
    }

    .top-nav {
        display: none;
    }
    .swiper-slide .description {
        font-size: 35px !important;
    }
    body {
        font-size: 16px;
    }

    .swiper-button-next {
        display: none !important;
    }
    .swiper-button-prev {
        display: none !important;
    }
    .amazon-btn {
        padding: 5px 20px !important;
    }

    .stay-touch {
        font-size: 25px !important;
    }

    .search-btn {
        position: relative !important;
        width: 100% !important;
        /* border-radius: 0px !important; */
        margin-top: 10px;
        height: fit-content !important;
    }
    .search-input {
        width: 100% !important;
        height: fit-content !important;
    }
    .footer {
        display: flex;
        flex-flow: column;
    }
    .comments {
        border-radius: 0px !important;
    }

    .review-container {
        padding: 10px 40px !important;
    }
}
</style>
