<template>
    <div>
        <NavBar :ContactPage="ContactPage"/>
        <ContactContent/>
        <Footer/>
    </div>
</template>

<script>
import NavBar from '../NavBar.vue';
import Footer from '../Home/Footer.vue';
import ContactContent from './ContactContent.vue';
    export default {
    name: "Contact",
    components: { NavBar, Footer, ContactContent },
    data() {
        return {
            ContactPage: false,
        }
    },
    mounted () {
        this.ContactPage = true;
    },
}
</script>

<style lang="scss" scoped>

</style>
