<template>
    <div class="container-jumbotron bg-white">
        <NavBar :HomePage="HomePage"/>
        <BookDisplay/>
        <NewsLetter/>
        <Footer/>
    </div>
</template>

<script>
import NavBar from './NavBar.vue';
import Footer from './Home/Footer.vue';
import NewsLetter from './Home/NewsLetter.vue';
import BookDisplay from './Home/BookDisplay.vue';
    export default {
    name: "HomePage",
    components: { NavBar, Footer, NewsLetter, BookDisplay },
    data() {
        return {
            HomePage: false,
          }
    },
    mounted () {
        this.HomePage = true;
    },

}
</script>

<style lang="scss" scoped>
</style>
