<template>
    <div>
        <div class="row">
            <div class="col-md-3 p-4 bg-white">
                <h4 class="px-4">Emails</h4>
                <div class="email-container px-4" v-if="emailLength !== 0">
                    <div
                        class="row px-2 pt-3 mb-3"
                        v-for="email in emails"
                        :key="email"
                        :class="[email.is_read == 0 ? 'unread' : 'email-row', openedMessage ==email.id? 'open-btn-clicked' : '']"
                         @click="openMessage(email.id)"
                    >
                        <p class="fw-bold mb-0">{{ email.name }}</p>
                        <p>{{ email.email }}</p>
                    </div>
                </div>
                <div v-else class="email-container">
                    <p>There is no email yet!</p>
                </div>
            </div>
            <div class="col-md-6 p-4">
                <div v-for="email in emails" :key="email">
                    <div class="message-container" v-if="openedMessage == email.id">
                        <p>{{email.message}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "Dashboard",
    data() {
        return {
            emails: null,
            emailLength: "",
            openedMessage: null,
        };
    },
    methods: {
        getEmails() {
            axios
                .get("/get/emails")
                .then((response) => {
                    this.emailLength = response.data.length;
                    this.emails = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        openMessage(id){
            this.openedMessage = id;
            axios.post("/open/message",{id} )
            .then((response) => {
                    this.getEmails();
            })
        }
    },
    mounted() {
        this.getEmails();
    },
};
</script>

<style lang="scss" scoped>
.email-row {
    background-color: #f3f9ff;
}
.email-container {
    height: 500px;
    overflow-y: auto;
}
.email-row:hover {
    cursor: pointer;
    background-color: #eff7ff;
}
.unread {
    background-color: #494949 !important;
    color: #fff;
    border-radius: 5px;
}
.unread:hover {
    background-color: #3c3c3c !important;
    color: #fff;
    cursor: pointer;
}
.open-btn-clicked{
    background-color: #e77600!important;
    color: white;
    border-radius: 5px;
}
</style>
