import { createApp } from 'vue';
import './bootstrap';
import App from './App.vue'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

import 'animate.css';

import router from './router/router'
const app = createApp(App)
app.use(router)
app.mount('#app')
