<template>
    <div class="container-jumbotron bg-white">
        <NavBar :AboutPage="AboutPage" />
        <div class="container p-5">
            <div class="row rounded-3">
                <div class="col-md-7 d-flex flex-column justify-content-center">
                    <p
                        class="fw-bold mb-0"
                        style="font-size: 25px"
                        data-aos="fade-down"
                        data-aos-duration="1000"
                    >
                        ABOUT ME
                    </p>
                    <div
                        class="mt-0 mb-3"
                        style="
                            width: 10%;
                            padding: 2px;
                            background-color: #e77600;
                            border-radius: 25px;
                        "
                    ></div>
                    <p
                        class="paragraph"
                        style="font-weight: 500; text-indent: 50px"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        Writer, Architect, Teacher of English and saxophonist,
                        Charles Muindi is the author behind C.Divine. Born in
                        Makueni, Kenya, he loves learning and doing things that
                        enrich his mind, glorify God and bless humanity.
                    </p>
                    <div class="">
                        <ul class="icons p-0">
                            <li><i class="fa-solid fa-thumbs-up"></i></li>
                            <li>
                                <a href="https://www.facebook.com/cdivvine/"
                                    ><i
                                        class="fa-brands fa-facebook-square"
                                    ></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/cdivvine/"
                                    ><i
                                        class="fa-brands fa-instagram-square"
                                    ></i>
                                </a>
                            </li>
                        </ul>
                        <p>Like & Share.</p>
                    </div>
                </div>
                <div class="col-md-5">
                    <img
                        src="images/about.png"
                        alt="About-image"
                        class="w-100"
                        data-aos="slide-up"
                        data-aos-duration="1000"
                    />
                </div>
            </div>
        </div>
        <div class="container mb-5 p-lg-5">
            <div class="row">
                <div
                    class="col-md-5 d-lg-flex align-items-lg-center justify-content-sm-center"
                >
                    <!-- <img src="images/faq.png" alt="" class="w-50"> -->
                </div>
                <div class="col-md-7">
                    <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                    >
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                >
                                    When did you start writing?
                                </button>
                            </h2>
                            <div
                                id="flush-collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div class="accordion-body">
                                    From high school, 2010. My first fictional
                                    book ‘Pieces of a Dream' was started in high
                                    school and has lived through the years with
                                    me until I completed university. The pieces
                                    of the dream are still coming together, I
                                    guess.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                >
                                    What is your inspiration to write?
                                </button>
                            </h2>
                            <div
                                id="flush-collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div class="accordion-body">
                                    ‘What's written is twice remembered’ I
                                    believe in the art of writing as a store of
                                    value for self and generations to come.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2
                                class="accordion-header"
                                id="flush-headingThree"
                            >
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                >
                                    Are there authors that inspire you?
                                </button>
                            </h2>
                            <div
                                id="flush-collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div class="accordion-body">
                                    Apostle Paul of Tarsus and Stephen King top
                                    the list of many others.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFour">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                >
                                    Do you write on a particular genre?
                                </button>
                            </h2>
                            <div
                                id="flush-collapseFour"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div class="accordion-body">
                                    I am working on Spiritual and Fictional
                                    books currently. I believe in a
                                    multidisciplinary education and application
                                    of knowledge, so I am open to try new ones
                                    later, maybe.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFive">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                >
                                    How is your interaction with a story or a
                                    character like?
                                </button>
                            </h2>
                            <div
                                id="flush-collapseFive"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFive"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div class="accordion-body">
                                    My characters have occasionally changed me
                                    to see their stories differently from what I
                                    had wanted them to be. As the
                                    characters/story evolves, the writings and I
                                    do likewise.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingSix">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSix"
                                >
                                    Something you'd like your readers to know
                                    that is not obvious?
                                </button>
                            </h2>
                            <div
                                id="flush-collapseSix"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingSix"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div class="accordion-body">
                                    I write love poems.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2
                                class="accordion-header"
                                id="flush-headingSeven"
                            >
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSeven"
                                >
                                    Do you have any guiding philosophy?
                                </button>
                            </h2>
                            <div
                                id="flush-collapseSeven"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingSeven"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div class="accordion-body">
                                    Love. Laugh. Live.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import NavBar from "../NavBar.vue";
import Footer from "../Home/Footer.vue";
export default {
    name: "About",
    components: { NavBar, Footer },
    data() {
        return {
            AboutPage: false,
        };
    },
    mounted() {
        this.AboutPage = true;
    },
};
</script>

<style lang="scss" scoped>
.accordion-item button:hover {
    outline: none;
    box-shadow: none;
    color: white !important;
    background-color: #e77600 !important;
}

.accordion-item button:focus {
    background-color: #007bff !important;
    color: white !important;
}

.accordion-item button {
    background-color: white !important;
    font-size: 18px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #323232 !important;
    margin-bottom: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.accordion-item:first-child button {
    background-color: #007bff !important;
    color: white !important;
}

.icons {
    list-style: none;
    font-size: 25px;
}

.icons a {
    text-decoration: none;
    color: black;
}

.paragraph::first-letter {
    font-size: 48px;
    color: #e77600;
    line-height: 0;
}

.paragraph {
    margin-top: 30px;
}
</style>
